<template>
  <div
    :class="{'is-dark': dark}"
    class="pricing-lines tw-flex tw-justify-between"
  >
    <component
      :is="cancelled ? 's' : 'div'"
      :class="{ cancelled: cancelled }"
      class="pricing-lines-label tw-pr-2 tw-whitespace-pre-line"
    >
      <slot name="label">
        {{ label | capitalize }}<br>
      </slot>
      <div
        v-if="comment"
        class="pricing-lines-comment tw-text-sm"
      >
        ({{ comment }})
      </div>
    </component>
    <component
      :is="cancelled ? 's' : 'div'"
      :class="{ cancelled: cancelled }"
      class="pricing-lines-slot"
    >
      <slot />
    </component>
  </div>
</template>

<script>
  /**
   * @module component - pricingLines
   * @param {string} label - Line label
   * @param {string} [comment=null] - Any additionnal comment to the price line
   * @param {boolean} [dark=false] - Specify if the line should be visually darken
   * @param {boolean} [cancelled=false] - Specify if the line should be stripped or not
   *
   * @example <caption>Simple usage</caption>
   * <pricing-line :label="'Pricing line label'">
   *   123,45 €
   * </pricing-line>
   *
   * @example <caption>Slot usage</caption>
   * <pricing-line>
   *   <span class="more-complex">
   *     123,45 €
   *   </span>
   * </pricing-line>
   */
  export default {
    name: 'PricingLines',
    props: {
      label: {
        type: String,
        required: true
      },
      comment: {
        type: String,
        default: null
      },
      dark: {
        type: Boolean,
        default: false
      },
      cancelled: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style lang="scss" scoped>

  .pricing-lines {
    border-bottom: 1px solid $light-gray;
    position: relative;
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #FFF;

    &.is-dark {
      border-bottom: 1px solid #FFF;
      background-color: $light-gray;
    }

    &-label {
      margin: auto 0;
      color: $secondary-text;
    }

    &-slot {
      white-space: nowrap;
    }

    &-comment {
      color: $third-text;
    }

    &:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      border-bottom: none;
    }

    & .cancelled {
      color: $third-text;
      opacity: 0.75;
    }
  }

</style>
